<template>
    <div>
      <b-col cols="12">
        <app-collapse id="faq-payment-qna" type="margin" class="mt-0 mb-0">
          <!-- Cancel Bidding Request -->
          <app-collapse-item
            title="Cancel Bidding Request"
            key="cancledBiddingRequest"
          >
            <b-form @submit.stop.prevent>
              <b-row>
                <!-- Bidding ID -->
                <b-col md="7">
                  <validation-observer ref="cancelBiddingRequestValidate">
                  <b-form-group label="Bidding Id" label-for="bidding_id">
                    <validation-provider
                      #default="{ errors }"
                      name="bidding_id"
                      rules="required"
                    >
                      <b-form-input
                        id="bidding_id"
                        type="text"
                        v-model="biddingId"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </validation-observer>
                </b-col>
                <!-- submit button -->
                <b-col md="6">
                  <b-button
                    @click="cancelBiddingModel"
                    size="lg"
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </b-button>
                </b-col>
                <b-modal
                  ref="change-processing-order-modal-validator"
                  cancel-variant="outline-secondary"
                  @ok="cancelBiddingRequest()"
                  ok-title="Ok"
                  cancel-title="Close"
                  centered
                      title="Validate Action"
                >
                  <b-row>
                    <b-col cols="12" class="my-2">
                      please enter the password to validate the request action
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Password" label-for="Password">
                            <b-form-input v-model="password" type="password" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-modal>
              </b-row>
            </b-form>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </div>
  </template>
  <script >
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import { required } from '@validations'
  export default {
    components: {
      AppCollapse,
      AppCollapseItem,
    },
    data() {
      return {
        required,
        biddingId: null,
          password:null,
      }
    },
    methods: {
      // change processing order to cancel status
      async cancelBiddingRequest() {
        let errors_back = []
        let showDismissibleAlert = false
        await axios
          .post('bidding-requests/' + this.biddingId + '/cancel/force', {'password' : this.password})
          .then((result) => {
            const data = result.data.data.data
  
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Bidding Request Changed To Cancel Success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((err) => {
            console.log(err)
            showDismissibleAlert = true
  
            if (err.response.data.data != null) {
              if (
                this.$store.dispatch('Errors/isString', err.response.data.data)
              ) {
                errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                errors_back = err.response.data.data
              }
            } else {
              errors_back.push({
                error: 'internal server error',
              })
            }
  
            this.$store.commit('Errors/setErrorsBack', errors_back)
            this.$store.commit(
              'Errors/setShowDisnissaibleAlert',
              showDismissibleAlert
            )
          })
      },
      cancelBiddingModel() {
        this.$refs.cancelBiddingRequestValidate.validate().then((success) => {
          if (success) {
            this.$refs['change-processing-order-modal-validator'].show()
          } else {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'Please Enter The Required Data',
              showConfirmButton: false,
              timer: 1400,
            })
          }
        })
      },
    },
    created() {
      this.authPermissions = this.$store.state.authPermissions
    },
    beforeDestroy() {
      this.$store.commit('Errors/setErrorsBack', [])
      this.$store.commit('Errors/setShowDisnissaibleAlert', false)
    },
  }
  </script>
  